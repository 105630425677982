<script setup lang="ts">
import type { TJob } from '@tl-ui/components/job/JobCard.vue'

const { $sentryCaptureException, $fbqTrack } = useNuxtApp()
const { saveJob, unSaveJob } = useJob()
const { status: authStatus } = useAuth()
const { gtag } = useGtag()
const viewport = useViewport()
const isMobile = computed(() => viewport.isLessThan('md'))
const runtimeConfig = useRuntimeConfig()

const props = defineProps({
  job: {
    type: Object as PropType<TJob>,
    required: true
  },
  size: {
    type: String as PropType<'small' | 'normal' | 'medium'>,
    required: false,
    default: 'small'
  },
  widthOverride: {
    type: String as PropType<string>,
    required: false,
    default: undefined
  },
  uiLocation: {
    type: String as PropType<string>,
    required: true
  }
})

const emit = defineEmits(['update:job'])
const showSaveModal = ref(false)
const loading = ref(false)
const localSaved = ref(props.job.saved)
const localSaveIdSlug = ref(props.job.savedIdSlug)

function handleSaved() {
  // emit GA save_job event for candidate, only candidate could save a job
  gtag('event', 'save_job', {
    location: props.uiLocation,
    industry: props.job.industry,
    company: props.job.companyName,
    role: props.job.role,
    job_title: props.job.title
  })
  // our facebook track
  // $fbqTrack('AddToWishlist', {
  //   content_name: props.job.title,
  //   content_category: 'JobPost',
  //   // @ts-ignore
  //   content_ids: [props.job.idSlug],
  //   delivery_category: props.uiLocation
  // })

  // emit AddToWishlist event for CCF on Gtag, meta and linkedin (only in production environment)
  if (runtimeConfig.public.environmentName === 'prod') {
    gtag('event', 'conversion', {
      send_to: 'AW-16894294884/T1frCP3V4aIaEOT26fc-',
      event_callback: () => {}
    })
    $fbqTrack('AddToWishlist', {})
    try {
      const _window: { lintrk: any } = window as any // Make typescript happy, because linktrk may not defined in window
      _window.lintrk('track', { conversion_id: 19184100 })
    } catch (error) {
      console.error('lintrk error', error)
    }
  }
}

async function handleSave() {
  if (authStatus.value === 'authenticated') {
    try {
      loading.value = true
      if (localSaved.value && localSaveIdSlug.value) {
        await unSaveJob(localSaveIdSlug.value)
        localSaved.value = false
        localSaveIdSlug.value = undefined
      } else {
        const saveRes = await saveJob(props.job.idSlug as string)
        if (!saveRes) {
          throw new Error('save job failed')
        }
        handleSaved()
        localSaved.value = true
        localSaveIdSlug.value = saveRes.id_slug
      }
      emit('update:job', {
        ...props.job,
        saved: localSaved.value,
        savedIdSlug: localSaveIdSlug.value
      })
    } catch (error) {
      console.error(error)
      $sentryCaptureException(error)
    }
    loading.value = false
  } else {
    showSaveModal.value = true
  }
}
</script>

<template>
  <div v-if="props.job" class="w-full">
    <JobModalSave
      :show="showSaveModal"
      :disabled="!props.job"
      :job="props.job"
      @update:show="
        (event: boolean) => {
          showSaveModal = event
        }
      "
      @update:save="
        (event: string) => {
          localSaved = true
          localSaveIdSlug = event
          handleSaved()
        }
      "
    />
    <ButtonBlackButton
      :text="localSaved ? $t('common.form.saved') : $t('common.form.save')"
      :variant="localSaved ? 'normal' : 'outlined'"
      :size="props.size"
      :width-override="props.widthOverride || isMobile ? '100%' : '6.75rem'"
      :loading="loading"
      @click="handleSave"
    >
      <template #left-icon>
        <IconsFigmaHeartShapeOutlined v-if="!localSaved" />
        <IconsFigmaHeartShapeFilled v-else />
      </template>
    </ButtonBlackButton>
  </div>
</template>
